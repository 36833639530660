import React from 'react'
import * as Form from '@radix-ui/react-form';
import { Icon } from '../../config/icons';

const Contact = () => {
   return (
      <Form.Root className="w-full">
         <Form.Field className="grid mb-[10px]" name="email">
            <Form.Control asChild>
               <input
                  className="mb-4 box-border w-full bg-[#eaf0ff] shadow-md inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black font-semibold focus-within:outline-blue-600"
                  type="text"
                  required
                  placeholder='Nome'
               />
            </Form.Control>
            <Form.Control asChild>
               <input
                  className="mb-4 box-border w-full bg-[#eaf0ff] shadow-md inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black font-semibold focus-within:outline-blue-600"
                  type="email"
                  required
                  placeholder='Email'
               />
            </Form.Control>
            <Form.Control asChild>
               <input
                  className="mb-4 box-border w-full bg-[#eaf0ff] shadow-md inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black font-semibold focus-within:outline-blue-600"
                  type="text"
                  required
                  placeholder='Nome da Empresa'
               />
            </Form.Control>
            <Form.Control asChild>
               <input
                  className=" mb-4 box-border w-full bg-[#eaf0ff] shadow-md inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black font-semibold focus-within:outline-blue-600"
                  type="text"
                  required
                  placeholder='Número do telefone'
               />
            </Form.Control>
            <Form.Control asChild>
               <textarea
                  className="mb-4 box-border w-full bg-[#eaf0ff] shadow-md inline-flex h-[35px] appearance-none items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-black font-semibold focus-within:outline-blue-600"
                  required
                  placeholder='Como podemos ajudar?'
               />
            </Form.Control>
         </Form.Field>
         <Form.Submit asChild>
            <button className="box-border w-full py-1 shadow-lg transition-colors hover:bg-black hover:text-white inline-flex h-[35px] items-center justify-center rounded-[4px] bg-white px-[15px] font-medium leading-none focus:outline-none mt-[10px]">
               Enviar proposta <Icon.rightArrow width={24} />
            </button>
         </Form.Submit>
      </Form.Root>
   )
}

export default Contact