import { useEffect } from "react"


export const UpdatedPageView = (data: any, url: string) => {
   useEffect(() => {
      const updatedData = async () => {
         try {
            const response = await fetch(url, {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(data),
            });

            if (response.ok) {
               console.log('User data saved successfully');
            } else {
               console.error('Failed to save user data');
            }
         } catch (error) {
            console.error('Error:', error);
         }
      };
      updatedData()
   }, [])

}

export const UpdatedPageAction = (data: any, url: string, clickEvent: number) => {
   useEffect(() => {
      const updatedData = async () => {
         try {
            const response = await fetch(url, {
               method: 'PUT',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(data),
            });

            if (response.ok) {
               console.log('action data updated successfully');
            } else {
               console.error('Failed to updated user data');
            }
         } catch (error) {
            console.error('Error:', error);
         }
      };
      if (clickEvent >= 1) {
         updatedData()
      }
   }, [clickEvent])

}