import React, { useState } from 'react'
import { Link } from 'gatsby'
import { LOCAL_DATA, data, siteConfi } from '../config/siteConfi'
import { motion } from 'framer-motion'
import { hideContentAnimation } from '../config/motionConfig'
import { Icon } from '../config/icons'
import { facebook, instagram, twitter, youTube } from '../images'
import { useMain } from '../context/provider'

const Footer = () => {
   const menu = LOCAL_DATA.siteMenu
   const [email, setEmail] = useState('')
   const social = [facebook, instagram, twitter, youTube]
   const { handleClick } = useMain()

   return (
      <footer className='mt-10 bg-[#adc6ff] rounded-t-2xl'>
         <div className="px-12">
            <div className='grid grid-cols-1 md:grid-cols-12 gap-2'>
               <div className="relative col-span-1 md:col-span-4 mt-4 grid place-content-between overflow-hidden divider">
                  <h3><b>Links Útils</b></h3>
                  <ul className='mb-6 sm:mb-0'>
                     {menu.map((item, index) => (
                        <li className='text-white' key={index}>
                           <Link to={`/${index === 0 ? '' : item.toLowerCase()}`} className='underline'>{item}</Link>
                        </li>
                     ))}
                  </ul>
                  <b className='mb-4 sm:mb-0'>Nosso NewsLatter</b>
                  <div className='relative'>
                     <input type="text" className='rounded-lg focus-visible:outline-none p-1 font-semibold' placeholder='Coloque seu email' onChange={(e) => setEmail(e.target.value)} />
                     <motion.button
                        variants={hideContentAnimation}
                        initial='hidden'
                        animate={email === '' ? 'hidden' : 'visible'}
                        className='bg-black text-white h-20 w-20 p-1 rounded-full'>Começar</motion.button>
                  </div>
               </div>
               <div className="relative col-span-1 md:col-span-4 text-center space-y-10 mt-4 grid place-content-between px-3 divider">
                  <div className='text-center'>
                     <p>{data.footer.description}</p>
                  </div>
                  <div>
                     {data.footer.logoName === '' ? (
                        <img src={data.footer.logo} className='w-9 h-9' width={200} height={200} alt="logo" />
                     ) : (<h3>{data.footer.logoName}</h3>)}
                  </div>
                  <div>
                     <p className='text-justify'>{data.footer.subDescription}</p>
                  </div>
               </div>
               <div className="relative col-span-1 md:col-span-4 mt-4 grid place-content-between px-3 text-center sm:text-left">
                  <h3 className='mb-4 sm:mb-0'><b>{data.footer.contactLable}</b></h3>
                  <ul className='grid gap-4'>
                     <li className='flex flex-col items-center sm:flex-row'>
                        <Icon.phone className='w-8 h-6' />
                        <b>(+244){data.footer.phoneNumber}</b>
                     </li>
                     <li className='flex flex-col items-center sm:flex-row'>
                        <Icon.evelope className='w-8 h-6' />
                        <b>{data.footer.email}</b>
                     </li>
                  </ul>
                  <ul className='flex flex-col items-center gap-5 my-6 sm:flex-row'>
                     {social.map((item, index) => (
                        <motion.li
                           key={index}
                           onClick={handleClick}
                           whileHover={{ y: -6, transition: { ease: 'easeInOut' } }}
                           className='rounded-lg w-9 h-9 shadow-lg flex items-center justify-center'>
                           <a href='https://www.facebook.com/' target='_blank'>
                              <img src={item} width={24} height={24} alt="social media icon" />
                           </a>
                        </motion.li>
                     ))}
                  </ul>
                  <div>
                     <b>Conexões Infinito © 2023. All rights reserved.</b>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer