export const fadeToRight = {
   hidden: {
      opacity: 0,
      x: -100, // Initial position for the image (left)
   },
   visible: {
      opacity: 1,
      x: 0, // Final position for the image (center)
      transition: {
         delay: 0.5, // Adjust the delay as needed
         duration: 1, // Animation duration
      },
   },
};

export const textFadeInAnimation = {
   hidden: {
      opacity: 0,
      x: -100, 
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 1.5, 
         duration: 1,
      },
   },
};
export const fodeToLefthText = {
   hidden: {
      opacity: 0,
      x: -100,
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 0.5,
         duration: 1,
      },
   },
};
export const dalayFadeInAnimation = {
   hidden: {
      opacity: 0,
      x: 100,
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 0.8,
         duration: 1,
      },
   },
};
export const hideContentAnimation = {
   hidden: {
      opacity: 0,
      x: -100,
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 0.5,
         duration: 1,
      },
   },
};
export const fadeToLeft = {
   hidden: {
      opacity: 0,
      x: 100,
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 1.9,
         duration: 1,
      },
   },
};
export const blueLine = {
   hidden: {
      opacity: 0,
      height: 0,
      y: 100,
   },
   visible: {
      opacity: 1,
      height: '100%',
      y: 0,
      transition: {
         delay: 0.5,
         duration: 1,
      },
   },
};
export const fadeInAnimationh = {
   hidden: {
      opacity: 0,
      x: -100,
   },
   visible: {
      opacity: 1,
      x: 0,
      transition: {
         delay: 1,
         duration: 1,
      },
   },
};
export const fadeInToTopAnimation = {
   hidden: {
      opacity: 0,
      y: -100,
   },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         delay: 0.7,
         duration: 1,
      },
   },
};
export const showOnHoverAnimation = {
   hidden: {
      opacity: 0,
   },
   visible: {
      opacity: 1,
      transition: {
         delay: 0.2,
         duration: 0.2,
      },
   },
};
export const scaleAnimation = {
   hidden: {
      scale: 1
   },
   visible: {
      scale: 1.5,
      transition: {
         delay: 0.2,
         duration: 0.2,
      },
   },
};