import React from 'react'
import * as Dialog from '@radix-ui/react-dialog';
import { Icon } from '../../config/icons';
import Contact from '../forms/ModalForm';
import Button from './Button';

const Modal = () => {
   return (
      <Dialog.Root>
         <Dialog.Trigger asChild>
            <Button
               className="shadow-lg inline-flex items-center justify-center rounded-md bg-white font-medium leading-none focus:outline-none transition-colors ease-in-out hover:text-white hover:bg-black">
               Consulta gratis
            </Button>
         </Dialog.Trigger>
         <Dialog.Portal>
            <Dialog.Overlay className="bg-[#000000bd] backdrop-blur-md data-[state=open]:animate-overlayShow fixed inset-0" />
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[96vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
               <Dialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                  Pronto para aumentar sua receita?
               </Dialog.Title>
               <Dialog.Description className="text-mauve11 mt-[10px] mb-5 text-[15px] leading-normal">
                  Quando você faz parceria com a Vestil, nós cuidamos do trabalho pesado, para que você possa aproveitar mais tráfego, leads e receita do site.
               </Dialog.Description>
               <Contact />
               <Dialog.Close asChild>
                  <Button
                     className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                     aria-label="Close"
                  >
                     <Icon.x />
                  </Button>
               </Dialog.Close>
            </Dialog.Content>
         </Dialog.Portal>
      </Dialog.Root>
   )
}

export default Modal