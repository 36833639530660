import React, { ReactNode, forwardRef } from 'react'
import { primary, secondary } from '../../config/siteConfi'
import { useMain } from '../../context/provider'
interface MyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   children: ReactNode,
   className?: string
}

const Button = forwardRef<HTMLButtonElement, MyButtonProps>(({ children, className, onClick, ...props }, ref) => {
   const { handleClick } = useMain()
   const handleCombinedClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (handleClick) handleClick(event);
      if (onClick) onClick(event);
   };
   return (
      <button
         {...props}
         ref={ref}
         className={`p-3 rounded-full ${className}`}
         onClick={handleCombinedClick}
         style={{ background: primary ? primary : '', color: secondary ? secondary : '' }}
      >
         {children}
      </button>
   )
})

export default Button