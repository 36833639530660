import React from 'react'
import { Link } from 'gatsby'
import { APIDATA, LOCAL_DATA } from '../config/siteConfi';
import Modal from './ui/Modal';
import MobileNav from './ui/MobileNav';
import { useMain } from '../context/provider';

const NavBar = () => {
   const data = (APIDATA.length === 0 ? LOCAL_DATA.siteMenu : {}) as string[]
   const { handleClick } = useMain()

   return (
      <header className='py-6'>
         <nav className="container flex items-center justify-between">
            <div>
               <h3 className='font-semibold text-2xl text-white'>Vestil</h3>
            </div>
            <div className='hide-items flex items-center gap-6'>
               {data.map((item, index) => (
                  <Link to={`/${index === 0 ? '' : item.toLowerCase()}`} onClick={handleClick} key={index}>{item}</Link>
               ))}
            </div>
            <div className='hidden sm:block'>
               <Modal />
            </div>
            <div className='sm:hidden'>
               <MobileNav />
            </div>

         </nav>
      </header>
   )
}

export default NavBar