import React from 'react'
import "./src/styles/global.css"
import { MainProvider } from './src/context/provider'

export const wrapRootElement = ({ element }) => {
     return(
      <MainProvider>
        {element}
      </MainProvider>
     )
}