import { PhoneIcon, Bars4Icon, EnvelopeIcon, ArrowUpRightIcon, CheckCircleIcon, ChevronLeftIcon, XMarkIcon, ArrowRightIcon, UserIcon, MagnifyingGlassIcon, CalendarIcon, HeartIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import { HeartIcon as like } from '@heroicons/react/24/outline'


export const Icon = {
   phone: PhoneIcon,
   bars: Bars4Icon,
   evelope: EnvelopeIcon,
   arrowUpRight: ArrowUpRightIcon,
   checkIcon: CheckCircleIcon,
   x: XMarkIcon,
   rightArrow: ArrowRightIcon,
   user: UserIcon,
   search: MagnifyingGlassIcon,
   calendar: CalendarIcon,
   liked: HeartIcon,
   send: ChevronLeftIcon,
   like,
   shareIcon: ArrowTopRightOnSquareIcon
}