import React, { MouseEventHandler, createContext, useContext, useEffect, useState } from 'react'
import { IMainContext, IProviderProps } from './types';
import { UpdatedPageAction } from '../hooks/use-fetch-data';
import Layout from '../components/Layout';

// eslint-disable-next-line no-undefined
const MainContext = createContext<IMainContext | undefined>(undefined)

export const useMain = (): any => {
   const context = useContext(MainContext)
   if (context === null) {
      throw new Error('useMain must be used within an AuthProvider')
   }
   return context
}

export const MainProvider: React.FC<IProviderProps> = ({ children }) => {
   const [clickCount, setClickCount] = useState(0)
   const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
      setClickCount(prev => prev + 1)
   };
   const data = {
      name: 'Reinaldo',
      action: 1
   }

   UpdatedPageAction(data, 'http://localhost:3000/user/updatedAction', clickCount)
   const value: IMainContext = {
      clickCount,
      handleClick,
   }
   return (
      <MainContext.Provider value={value}>
         <Layout>
            {children}
         </Layout>
      </MainContext.Provider>
   )
}